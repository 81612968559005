import { Table, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { updatestudent } from '../Services/AllFunctions';
import { QuestionCircleOutlined } from '@ant-design/icons';

const StudentTable = ({ allstudentinfo, setShowstu, setStudentId, setStudent, fetchData,fillt }) => {
  const [pestatus, setPerstatus] = useState("");
  const [sortedStudents,setSortedStudents]=useState([])
  const formdata = {
    Status: pestatus
  };

  const makeinactive = async (studentId) => {
    await updatestudent(formdata, studentId);
    fetchData();
  };

  const updatestude = (status) => {
    if (status === true) {
      setPerstatus(false);
    } else {
      setPerstatus(true);
    }
  };
useEffect(()=>{
  if(fillt === true){
    setSortedStudents(allstudentinfo)

  }else{
    const sortedStudent = [...allstudentinfo].sort((a, b) => a.fullName.localeCompare(b.fullName));
    setSortedStudents(sortedStudent)

  }
},[allstudentinfo])


  // Sort the allstudentinfo array alphabetically by fullName

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <span className='underline' style={{ cursor: "pointer" }} onClick={() => { setShowstu(false); setStudent(true); setStudentId(record._id); }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
    },
    {
      title: 'Completion Date',
      dataIndex: 'dateoflast',
      key: 'dateoflast',
      render: (text,record) => (
        <span>
          {/* {record ? new Date(text).toISOString().split('T')[0] : "Invalid Date"} */}
          {
  record.courseEnrollments[0].completed ? (
    record.courseEnrollments[0]?.dateoflast
      ? record.courseEnrollments[0].dateoflast.split('T')[0]
      : record.updatedAt.split('T')[0]
  ) : (
    "Not Completed Yet"
  )
}

          
        </span>
      )
    },
    {
      title: 'Course Type',
      key: 'courseType',
      render: (text, record) => (
        <span>
          {record.courseEnrollments[0].name} {record.courseEnrollments[0].language}
        </span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (Status) => (
        (Status.Status === true ? "Active" : "Inactive")
      ),
    },
    {
      title: '% Of Completion',
      dataIndex: 'courseEnrollments',
      key: 'percent',
      render: (courseEnrollments) => (
        <div className='d-flex align-items-center gap-2'>
          <div className='studyprog'>
            <div className='realprogress' style={{ width: `${courseEnrollments[0].percent}%` }}></div>
          </div>
          {Math.round(courseEnrollments[0].percent)}%
        </div>
      ),
    },
    {
      title: 'More',
      key: 'Status',
      render: (Status) => (
        <div class="dropdown">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <path d="M16.166 17.667C16.9944 17.667 17.666 16.9954 17.666 16.167C17.666 15.3386 16.9944 14.667 16.166 14.667C15.3376 14.667 14.666 15.3386 14.666 16.167C14.666 16.9954 15.3376 17.667 16.166 17.667Z" fill="#0A0A0A" />
            <path d="M16.166 9.66699C16.9944 9.66699 17.666 8.99542 17.666 8.16699C17.666 7.33857 16.9944 6.66699 16.166 6.66699C15.3376 6.66699 14.666 7.33857 14.666 8.16699C14.666 8.99542 15.3376 9.66699 16.166 9.66699Z" fill="#0A0A0A" />
            <path d="M16.166 25.667C16.9944 25.667 17.666 24.9954 17.666 24.167C17.666 23.3386 16.9944 22.667 16.166 22.667C15.3376 22.667 14.666 23.3386 14.666 24.167C14.666 24.9954 15.3376 25.667 16.166 25.667Z" fill="#0A0A0A" />
          </svg>
          <ul class="dropdown-menu">
            <li><span class="dropdown-item" style={{ cursor: "pointer" }} onClick={() => { setShowstu(false); setStudent(true); setStudentId(Status._id); }}  >Edit</span></li>
            <li>
              <span className='dropdown-item'>
                <Popconfirm
                  title="Change Status"
                  description="Are you sure to Change Status?"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={() => { makeinactive(Status._id) }}
                >
                  <span onClick={() => { updatestude(Status.Status) }}> {Status.Status === true ? ("Inactivate") : ("Activate")}</span>
                </Popconfirm>
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sortedStudents} // Use the sortedStudents array
      rowKey={(record) => record._id}
    />
  );
};

export default StudentTable;
